<template>
  <div class="w1004">
    <div class="pagenamew">
      <h3 class="pagename">{{$route.matched[$route.matched.length-1].meta.text}}</h3>
    </div>

    <div class="mybetwrap">
      <ul class="tabs">
        <li class="tab-link current" data-tab="tab-1">
          <img src="@/assets/img/folder.png" alt=""/>
          <span>전체</span>
          <span class="betnum">854</span>
        </li>
        <li class="tab-link" data-tab="tab-2">
          <img src="@/assets/img/soccer.png" alt=""/>
          <span>축구</span>
          <span class="betnum">567</span>
        </li>
        <li class="tab-link" data-tab="tab-3">
          <img src="@/assets/img/basketball.png" alt=""/>
          <span>농구</span>
          <span class="betnum">227</span>
        </li>
        <li class="tab-link" data-tab="tab-4">
          <img src="@/assets/img/baseball.png" alt=""/>
          <span>야구</span>
          <span class="betnum">407</span>
        </li>
        <li class="tab-link" data-tab="tab-5">
          <img src="@/assets/img/volleyball.png" alt=""/>
          <span>배구</span>
          <span class="betnum">0</span>
        </li>
        <li class="tab-link" data-tab="tab-7">
          <img src="@/assets/img/hockey.png" alt=""/>
          <span>하키</span>
          <span class="betnum">0</span>
        </li>
        <li class="tab-link" data-tab="tab-6">
          <img src="@/assets/img/football.png" alt=""/>
          <span>미식축구</span>
          <span class="betnum">0</span>
        </li>
        <li class="tab-link" data-tab="tab-8">
          <img src="@/assets/img/boxing.png" alt=""/>
          <span>격투기</span>
          <span class="betnum">9</span>
        </li>
      </ul>

      <div id="tab-1" class="tab-content current">
        <div class="datesearch">
          <ui-select :items="sportCategory" :className="'w100 mr5'" :value="filter.category" @input="getCategory"/>
          <ui-input :type="'text'" :className="'w100 mr5'" :placeholder="'팀명'" :modelValue="filter.teamName" @input="getTeamName"/>
          <ui-date :range="0" @input="getDate"/>
          <ui-button :text="'검색'" :className="'bgf'" @click="onSearch"/>
        </div>

        <div class="betlistwrap">
          <ul class="betlisthead">
            <li class="w80">경기일시</li>
            <li class="w40">종목</li>
            <li class="w220">승(홈)</li>
            <li class="w40">무</li>
            <li class="w220">패(원정)</li>
            <li class="w40">스코어</li>
            <li>결과</li>
          </ul>

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li>MLS</li>
              <li></li>
            </ul>
            <ul class="betlistconlist">
              <li class="w80">2021-06-29 15:45</li>
              <li class="w40">농구</li>
              <li>
                <ul class="betlistconper">
                  <li class="ltscr w220 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w220"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
              <li class="w40">4:4</li>
              <li class="rdc">승</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w80">2021-06-29 15:45</li>
              <li class="w40">농구</li>
              <li>
                <ul class="betlistconper">
                  <li class="ltscr w220"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>2.96</span></li>
                  <li class="ryscr w220 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
              <li class="w40">222:222</li>
              <li>패</li>
            </ul>
          </div><!-- betlistcon -->

        </div>

        <pagination />

      </div><!-- tab-1 -->

      <div id="tab-2" class="tab-content">

      </div><!-- tab-2 -->

      <div id="tab-3" class="tab-content">

      </div><!-- tab-3 -->

      <div id="tab-4" class="tab-content">

      </div><!-- tab-4 -->

      <div id="tab-5" class="tab-content">

      </div><!-- tab-5 -->

      <div id="tab-6" class="tab-content">

      </div><!-- tab-6 -->

      <div id="tab-7" class="tab-content">

      </div><!-- tab-7 -->

      <div id="tab-8" class="tab-content">

      </div><!-- tab-8 -->

    </div><!-- mybetwrap -->

  </div>
</template>

<script>
import UiDate from '@/components/ui/UiDate'
import { SPORT_GAME_CATEGORY } from '@/libs/constants'
import UiSelect from '@/components/ui/UiSelect'
import UiButton from '@/components/ui/UiButton'
import Pagination from '@/components/ui/Pagination'
import { cloneDeep } from '@/libs/utils'
import UiInput from '@/components/ui/UiInput'
export default {
  name: 'ResultSport',
  components: { UiInput, Pagination, UiButton, UiSelect, UiDate },
  data () {
    return {
      sportCategory: SPORT_GAME_CATEGORY,
      filter: {
        category: 'all',
        teamName: '',
        startDate: '',
        endDate: ''
      }
    }
  },
  created () {

  },
  methods: {
    getCategory (value) {
      this.filter.category = value
    },
    getTeamName (value) {
      this.filter.teamName = value
    },
    getDate (startDate, endDate) {
      this.filter.startDate = startDate
      this.filter.endDate = endDate
    },
    onSearch () {
      cloneDeep(this.filter)
    }
  }
}
</script>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
